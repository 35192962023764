import { useMutation } from 'react-query';
import { addItem } from './api/mutate';

import { useCompareQueryKey } from './useCompare';

const useAddToCompare = (queryClient) => useMutation(({ id }) => addItem(id), {
  onSettled: () => {
    queryClient.invalidateQueries(useCompareQueryKey);
  },
});

// eslint-disable-next-line import/prefer-default-export
export { useAddToCompare };
