import { useQuery } from 'react-query';

const load = (src) => new Promise((resolve, reject) => {
  try {
    const script = document.createElement('script');
    script.async = true;
    script.onload = () => resolve({
      unload: () => document.body.removeChild(script),
    });
    script.onerror = (e) => reject(e);
    script.src = src;
    document.body.appendChild(script);
  } catch (e) {
    reject(e);
  }
});

export default (src, opts) => useQuery(['script', src], () => load(src), {
  ...opts,
  refetchOnWindowFocus: false,
});
