import CompareCache from './cache';
import { request } from './request';

const cache = new CompareCache();

const query = `
  mutation {
    createCompareList(
      input: {
        products: []
      }
    ) {
      uid
    }
  }
`;

const createList = async () => {
  const {
    createCompareList: {
      uid: listUid,
    },
  } = await request(query);

  await cache.setId(listUid);

  return listUid;
};

// eslint-disable-next-line import/prefer-default-export
export { createList };
