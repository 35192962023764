import { useMutation } from 'react-query';

import { useCartQueryKey } from './useCart';
import { addToCart } from './api/addToCart';

const useAddToCart = (queryClient, onSuccess = (() => {})) => useMutation(
  ({ sku, qty, customOptions }) => addToCart(sku, qty, customOptions),
  {
    onSuccess,
    onSettled: () => {
      queryClient.invalidateQueries(useCartQueryKey);
    },
  },
);

// eslint-disable-next-line import/prefer-default-export
export { useAddToCart };
