import React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';

import t from '../../../lib/translate';

import { useAddToCart } from '../../../lib/service/cart/useAddToCart';
import { useRemoveFromCart } from '../../../lib/service/cart/useRemoveFromCart';
import { useOpenShopCart } from '../../../lib/service/cart/useOpenShopCart';

import LoadingButton from '../../LoadingButton';

const ProductAddToCart = ({
  sku,
  qty = 1,
  customOptions = {},
  onSuccess = async () => {},
  replaceUid = undefined,
  preorder = false,
}) => {
  if (qty < 1) {
    throw new Error(`cannot add less than 1 to cart for sku ${sku}`);
  }

  const queryClient = useQueryClient();

  const openShopCart = useOpenShopCart();
  const removeFromCart = useRemoveFromCart(queryClient);
  const addToCart = useAddToCart(queryClient, async () => {
    await onSuccess();
    if (replaceUid) {
      await openShopCart.mutate();
    }
  });

  const onClick = async () => {
    if (replaceUid) {
      try {
        await removeFromCart.mutateAsync({
          uid: replaceUid,
        });
      } catch (e) {
        // fail silently
        return;
      }
    }

    try {
      await addToCart.mutateAsync({
        sku,
        qty,
        customOptions,
      });
    } catch (e) {
      // fail silently
    }
  };

  return (
    <>
      <LoadingButton
        className="btn-primary product-button-cart"
        icon="cart"
        type="button"
        isLoading={removeFromCart.isLoading || addToCart.isLoading || openShopCart.isLoading}
        isSuccess={replaceUid !== undefined ? (removeFromCart.isSuccess && addToCart.isSuccess && openShopCart.isSuccess) : addToCart.isSuccess}
        isError={replaceUid !== undefined ? (removeFromCart.isError || addToCart.isError || openShopCart.isError) : addToCart.isError}
        onClick={onClick}
      >
        {!replaceUid && preorder && t`product_action_cart_preorder_label`}
        {!replaceUid && !preorder && t`product_action_cart_add_label`}
        {replaceUid && t`product_action_cart_edit_label`}
      </LoadingButton>

      {removeFromCart.isError && (
        <span className="product-button-cart-error">{removeFromCart.error.toString()}</span>
      )}

      {addToCart.isError && (
        <span className="product-button-cart-error">{addToCart.error.toString()}</span>
      )}

      {openShopCart.isError && (
        <span className="product-button-cart-error">{openShopCart.error.toString()}</span>
      )}
    </>
  );
};

ProductAddToCart.defaultProps = {
  qty: 1,
  customOptions: {},
  onSuccess: () => {},
  replaceUid: undefined,
  preorder: false,
};

ProductAddToCart.propTypes = {
  sku: PropTypes.string.isRequired,
  qty: PropTypes.number,
  customOptions: PropTypes.objectOf(PropTypes.any),
  onSuccess: PropTypes.func,
  replaceUid: PropTypes.string,
  preorder: PropTypes.bool,
};

export default ProductAddToCart;
