import { CompareBackendResponseError } from './errors';

const request = async (query, variables) => {
  const res = await fetch(
    new Request(process.env.GATSBY_SERVICE_COMPARE_BASEURL), {
      method: 'POST',
      headers: new Headers({
        Store: process.env.GATSBY_SERVICE_COMPARE_STORECODE,
        'content-type': 'application/json',
      }),
      body: JSON.stringify({
        query,
        variables,
      }),
    },
  );

  if (!res.ok || res.status !== 200) {
    throw new Error('compare service: invalid status');
  }

  const { data, errors } = await res.json();
  if (errors) {
    const msg = `compare service graphql error: ${errors[0].message}`;
    // category "graphql" == graphql syntax error
    if (errors[0].extensions?.category === 'graphql') {
      throw new Error(msg);
    }
    throw new CompareBackendResponseError(msg);
  }

  return data;
};

// eslint-disable-next-line import/prefer-default-export
export { request };
