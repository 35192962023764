import { Cache } from './cache';

class TrimmedSkuCache extends Cache {
  _maxItems;

  constructor(name, version, expiration, maxItems) {
    super(
      name,
      version,
      expiration,
    );

    this._maxItems = maxItems;
  }

  async addSku(sku) {
    const known = await this.getSkus();
    if (known.includes(sku)) {
      return false;
    }

    let skus = [...[sku], ...known];
    skus = skus.slice(0, this._maxItems); // trim

    await this.setItem('skus', skus);
    return true;
  }

  async getSkus() {
    const items = await this.getItem('skus');
    return items || [];
  }
}

export default TrimmedSkuCache;
