import CompareCache from './cache';
import { request } from './request';
import { CompareBackendResponseError } from './errors';

const query = `
  query($uid: ID!) {
    compareList(
      uid: $uid
    ) {
      attributes {
        code
        label
      }
      items {
        product {
          id
          name
        }
        attributes {
          code
          value
        }
      }
    }
  }
`;

const cache = new CompareCache();

const getContent = async () => {
  const listUid = await cache.getId();
  if (!listUid) {
    return undefined;
  }

  const content = await cache.getContent();
  if (content) {
    return content;
  }

  let response;

  try {
    response = await request(
      query,
      {
        uid: listUid,
      },
    );
  } catch (e) {
    if (e instanceof CompareBackendResponseError) {
      await cache.clear();
    }
    throw e;
  }

  if (!response?.compareList || !response?.compareList?.items) {
    return undefined;
  }

  await cache.setContent(response.compareList);

  return response.compareList;
};

// eslint-disable-next-line import/prefer-default-export
export { getContent };
