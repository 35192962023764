import { useQuery } from 'react-query';

import { getContent } from './api/getContent';

const useCompareQueryKey = ['compare', 'content'];

const useCompare = () => useQuery(useCompareQueryKey, getContent, {
  retry: false,
});

export { useCompareQueryKey, useCompare };
