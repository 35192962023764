import CompareCache from './cache';
import { createList } from './createList';
import { request } from './request';
import { CompareBackendResponseError } from './errors';

const cache = new CompareCache();

const addQuery = `
  mutation($uid: ID!, $productId: ID!) {
    addProductsToCompareList(
      input: {
        products: [$productId]
        uid: $uid
      }
    ) {
      uid
    }
  }
`;

const removeQuery = `
  mutation($uid: ID!, $productId: ID!) {
    removeProductsFromCompareList(
      input: {
        products: [$productId]
        uid: $uid
      }
    ) {
      uid
    }
  }
`;

const mutate = async (productId, query) => {
  let listUid = await cache.getId();
  if (!listUid) {
    listUid = await createList();
  }

  try {
    await request(
      query,
      {
        uid: listUid,
        productId,
      },
    );
  } catch (e) {
    if (e instanceof CompareBackendResponseError) {
      await cache.clear();
    }
    throw e;
  }

  await cache.removeContent();
};

const addItem = (productId) => mutate(productId, addQuery);
const removeItem = (productId) => mutate(productId, removeQuery);

// eslint-disable-next-line import/prefer-default-export
export { addItem, removeItem };
