import React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';

import t from '../../../lib/translate';

import { useAddToCompare } from '../../../lib/service/compare/useAddToCompare';

import withCompareFeature from '../../../hoc/withCompareFeature';

import LoadingButton from '../../LoadingButton';

const AddToCompare = ({
  id,
}) => {
  const queryClient = useQueryClient();
  const {
    isLoading,
    isSuccess,
    isError,
    mutate,
  } = useAddToCompare(queryClient);

  return (
    <LoadingButton
      type="button"
      className="btn btn-link"
      icon="compare"
      iconColor="gold"
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      onClick={() => mutate({
        id,
      })}
    >
      <span>
        {t`product_action_compare_add_label`}
      </span>
    </LoadingButton>
  );
};

AddToCompare.propTypes = {
  id: PropTypes.number.isRequired,
};

export default withCompareFeature(AddToCompare);
