import TrimmedSkuCache from '../../trimmed-sku-cache';
import { DefaultLifetime } from '../../cache';

class LastSeenCache extends TrimmedSkuCache {
  constructor(maxItems) {
    super(
      'last-seen',
      2,
      DefaultLifetime,
      maxItems,
    );
  }
}

export default LastSeenCache;
