import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Section from '../../component-elements/content-section';

const query = graphql`
query{
    allDatoCmsConfig {
        nodes {
            locale
            paymentMethodLogos {
                ... on DatoCmsPaymentMethodLogoImage {
                    __typename
                    id
                    image {
                        alt
                        title
                        gatsbyImageData(
                            forceBlurhash: true,
                            layout: FIXED
                            imgixParams: {w: "150", h: "50", auto: "compress", dpr: 1.5, q: 70}
                        )
                    }
                }
                ... on DatoCmsPaymentMethodLogoText {
                    __typename
                    id
                    name
                }
            }
        }
    }
}`;

const PaymentMethodLogos = ({ lang }) => (
  <StaticQuery
    query={query}
    render={({ allDatoCmsConfig: config }) => (
      <Section background="Grau" noGrid>
        <div className="product-payment-methods">
          {config.nodes.find((c) => c.locale === lang).paymentMethodLogos.map((logo) => {
            switch (logo.__typename) {
              case 'DatoCmsPaymentMethodLogoImage':
                if (!logo.image.gatsbyImageData) {
                  // eslint-disable-next-line no-console
                  console.log(`PaymentMethodLogos logo ${logo.id} image not defined`);
                  return null;
                }
                return (
                  <div key={logo.id} className="product-payment-method">
                    <GatsbyImage
                      image={getImage(logo.image.gatsbyImageData)}
                      alt={logo.image.alt}
                      title={logo.image.title}
                    />
                  </div>
                );
              case 'DatoCmsPaymentMethodLogoText':
                return (<div key={logo.id} className="product-payment-method">{logo.name}</div>);
              default:
                return null;
            }
          })}
        </div>
      </Section>
    )}
  />
);

PaymentMethodLogos.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default PaymentMethodLogos;
