import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import ProductView from '../components/shop-product/view';
import { useUrlParam } from '../lib/useUrlParam';

const ProductPage = ({
    data,
    location
}) => {
    const lang = process.env.GATSBY_APP_LANG;

    const { magentoProduct, datoCmsConfig: config } = data;

    let { pageProductInquiry, pageProductDetective } = config;

    if (pageProductInquiry.locale !== lang) {
        pageProductInquiry = null;
    }

    if (pageProductDetective.locale !== lang) {
        pageProductDetective = null;
    }

    const replaceCartUid = useUrlParam('ec');

    return (
        <ProductView
            lang={lang}
            product={magentoProduct}
            productDetailPath={config.productDetailPath}
            inquiryUrlPath={pageProductInquiry?.fullUrlPath}
            detectiveUrlPath={pageProductDetective?.fullUrlPath}
            breadcrumb={location.state?.breadcrumb}
            cmsContent={config.productDetailsCmsContent}
            replaceCartUid={replaceCartUid?.value}
            url={location.href}
        />
    );
};

export default ProductPage;

ProductPage.defaultProps = {
    location: {}
};

ProductPage.propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    location: PropTypes.objectOf(PropTypes.any)
};

/* eslint-disable no-tabs */
export const query = graphql`
  query productPage($id: String!, $lang: String!) {
		datoCmsConfig(locale: { eq: $lang }) {
			productDetailPath
			pageProductInquiry {
				locale
				fullUrlPath
			}
			pageProductDetective {
				locale
				fullUrlPath
			}
			productDetailsCmsContent {
				... on DatoCmsTextSimple {
					...contentElementTextSimple
				}
				... on DatoCmsBrandContact {
					...contentElementContact
				}
			}
		}

		magentoProduct(id: { eq: $id }) {
			...shopProductPrices
			...shopProductAvailability
			...shopProductFlags

			meta_title
			meta_description

			name
			magentoId: magento_id
			sku
			urlKey: url_key
			jr_manufacturer_number
			jr_ean
			jr_release_date
			jr_preorderable
			releaseDate: jr_release_date(formatString: "DD.MM.YYYY")

			categories {
				name
			}
				
			description {
				html
				text
			}

			small_image {
				url
				label
			}

			image {
				url
				label
			}

			media_gallery {
				src: proxyUrl(width: 1280, quality: 75)
				srcTablet: proxyUrl(width: 790, quality: 75)
				srcMobile: proxyUrl(width: 420, quality: 75)
				thumbnail: proxyUrl(width: 240, quality: 40)
				position
			}
			dwArticleGroupCategory {
				id
				name
				url_path
			}
			dwArticleTypeCategory {
				id
				name
				url_path
			}

			detailPageAttributes {
        		position
				code
				label
				textValues
			}

			crossSelling: magentoCrossSelling {
				...shopProductListItem
			}
			
			related: magentoRelatedProducts {
			  ...shopProductListItem
			}

			customizeOptions: options {
				title
				required
				sort_order
				type
				uid
        
				dropDownValues {
					option_type_id
					price
					price_type
					sort_order
					title
				}
			}
			
			cmsArticleLine {
			  name
			  fullUrlPath
			}

			cmsBrand {
				name
				fullUrlPath
				productCollectionsCount
				occasionCollectionsCount
				collectionsUrlPath
        		productsUrlPath
				description {
					value
					links {
						... on DatoCmsPage {
							__typename
							originalId
							name
							fullUrlPath
						}
						... on DatoCmsNews {
							__typename
							originalId
							title
							fullUrlPath
						}
						... on DatoCmsBrand {
							__typename
							originalId
							name
							isExternal
							fullUrlPath
						}
						... on DatoCmsShopCategory {
							__typename
							originalId
							magentoCategory {
								fullUrlPath
								name
							}
						}
					}
				}

				logoBlack: logo {
					alt
					title
					gatsbyImageData(
						imgixParams: {
							w: "200"
							maxH: 50
							auto: "compress"
							q: 70
							duotone: "fff,000"
						}
					)
				}

				logoGold: logo {
					alt
					title
					gatsbyImageData(
						imgixParams: {
							w: "200"
							maxH: 50
							auto: "compress"
							q: 70
							duotone: "fff,AE9962"
						}
					)
				}
			}

		}
	}
`;
