import React from 'react';
import PropTypes from 'prop-types';

import CmsTextSimpleComponent from '../../../content-elements/textSimple';
import CmsBrandContactComponent from '../../../content-elements/brandContact';

const ProductCmsContent = ({ elements = [] }) => (
  <>
    {elements.map((ce) => {
      switch (ce.__typename) {
        case 'DatoCmsTextSimple':
          return (<CmsTextSimpleComponent key={ce.id} {...ce} />);
        case 'DatoCmsBrandContact':
          return (<CmsBrandContactComponent key={ce.id} {...ce} />);
        default:
          return null;
      }
    })}
  </>
);

ProductCmsContent.defaultProps = {
  elements: [],
};

ProductCmsContent.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.shape({
    __typename: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  })),
};

export default ProductCmsContent;
