/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import isNotEmpty from '../../lib/is-not-empty-structured-text';

import CmsTextComponent from '../text';
import StructuredTextButton from '../textButton';
import Section from '../../component-elements/content-section';

import * as TextSimpleStyle from './component.module.scss';

const CmsTextSimpleComponent = (props) => {
  const {
    headline,
    title,
    content,
    button,
    background,
    multicolumn,
  } = props;

  return (
    <Section noGrid background={background}>
      {
        multicolumn === true ? (
          <>
            {
                (isNotEmpty(headline) || title) && (
                <header className="section-header">
                    { isNotEmpty(headline) && <CmsTextComponent content={headline} /> }
                  { title && <span className={TextSimpleStyle.sectionSubtitle}>{title}</span>}
                </header>
              )
            }
            <div className="multicolumn-text">
                <CmsTextComponent content={content} />
                <StructuredTextButton content={button} />
            </div>
          </>
        )
          : (
            <>
              {
                  (isNotEmpty(headline) || title) && (
                  <header className="section-header">
                      { isNotEmpty(headline) && <CmsTextComponent content={headline} /> }
                    { title && <span className={TextSimpleStyle.sectionSubtitle}>{title}</span>}
                  </header>
                )
              }
                <CmsTextComponent content={content} />
                <StructuredTextButton content={button} />
            </>
          )
      }
    </Section>
  );
};

CmsTextSimpleComponent.defaultProps = {
  multicolumn: null,
  headline: {},
};

CmsTextSimpleComponent.propTypes = {
  title: PropTypes.string.isRequired,
  button: PropTypes.objectOf(PropTypes.any).isRequired,
  headline: PropTypes.objectOf(PropTypes.any),
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  background: PropTypes.string.isRequired,
  multicolumn: PropTypes.bool,
};

export default CmsTextSimpleComponent;
