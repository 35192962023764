import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { Swiper, SwiperSlide } from 'swiper/react';

import t from '../../../lib/translate';
import { useAddToLastSeen, useLastSeen } from '../../../lib/service/catalog/last-seen';

import Section from '../../../component-elements/content-section';
import Product from '../item';

import 'swiper/swiper.scss';

const LastSeen = ({ sku, urlPrefix, lang }) => {
  const queryClient = useQueryClient();
  const addToLastSeen = useAddToLastSeen(queryClient);

  useEffect(() => {
    addToLastSeen.mutate({ sku });
  }, []);

  const lastSeen = useLastSeen(sku, addToLastSeen.isSuccess);
  if (!lastSeen.isSuccess || lastSeen.data.length === 0) {
    return null;
  }

  return (
    <Section noGrid background="Weiß">
      <header className="section-header">
        <h4>{t`product_lastseen_title`}</h4>
      </header>

      <div className={`slider product-slider has-items-${lastSeen.data.length}`}>
        <span className="icon icon-left icon-gold swiper-button swiper-button-prev prev prev-lastseen">
          <span className="sr-only">{t`pager_prev_label`}</span>
        </span>
        <span className="icon icon-right icon-gold swiper-button swiper-button-next next next-lastseen">
          <span className="sr-only">{t`pager_next_label`}</span>
        </span>
        <Swiper
          slidesPerView={lastSeen.data.length >= 3 ? 3 : lastSeen.data.length}
          spaceBetween={30}
          slidesPerGroup={1}
          loop
          watchOverflow
          loopedSlides={50}
          navigation={{
            prevEl: '.prev-lastseen',
            nextEl: '.next-lastseen',
          }}
          breakpoints={{
            1275: {
              slidesPerView: lastSeen.data.length >= 3 ? 3 : lastSeen.data.length,
            },
            767: {
              slidesPerView: lastSeen.data.length >= 2 ? 2 : lastSeen.data.length,
            },
            320: {
              slidesPerView: 1,
            },
          }}
          speed={1000}
          className={`has-items-${lastSeen.data.length}`}
        >
          {lastSeen.data.map((product) => (
            <SwiperSlide key={`lastseen-${product.sku}`} className="swiper-slide">
              <Product
                lang={lang}
                urlPrefix={urlPrefix}
                withAddToWishlist={false}
                imageSize={520}
                {...product}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Section>
  );
};

LastSeen.propTypes = {
  sku: PropTypes.string.isRequired,
  urlPrefix: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
};

export default LastSeen;
