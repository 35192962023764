/*
  nicht abgerufen
  - jr_manufacturer_status:
    ändert sich nur von false zu true (überlicherweise nicht in die andere Richtung)
    im schlimmsten Fall bleibt ein Produkt etwas zu lang als "nicht verkäuflich" markiert
  - jr_delivery_time
    ist abhängig davon, wie schnell ein Hersteller ein Produkt liefern kann
    hat deshalb eine sehr niedrige Änderungsfrequenz und muss nicht zwingend geladen werden
  - jr_reorderable
    ist vom Hersteller abhängig und ändert sich üblicherweise nur sehr selten
  - jr_release_date
    ändert nur den Status des Produktes von "noch nicht veröffentlicht" zu "veröffentlicht" (überlicherweise nicht in die andere Richtung)
    im schlimmsten Fall bleibt ein Produkt etwas zu lang im Status "noch nicht veröffentlicht"
*/
const query = encodeURIComponent(`query($sku: String!) {
  products( 
    filter: { 
      sku: {
        eq: $sku
      }
    }
  ) { 
    items {
      dwInStock
      description {
        html
      }
      price_range {
        minimum_price {
          regular_price {
            currency
            value
          }
          final_price {
            currency
            value
          }
        }
      }
    }
  }
}`).replace(/\s+/g, ' ');

const getProductDetails = async (sku) => {
  const vars = JSON.stringify({
    sku,
  });

  const res = await fetch(
    new Request(`${process.env.GATSBY_SERVICE_CATALOG_BASEURL}?query=${query}&variables=${vars}`), {
      method: 'GET',
      headers: new Headers({
        Store: process.env.GATSBY_SERVICE_CATALOG_STORECODE,
      }),
    },
  );

  if (!res.ok || res.status !== 200) {
    throw new Error('catalog service request error');
  }

  const { data, errors } = await res.json();
  if (errors && errors.length > 0) {
    throw new Error(`catalog service: ${errors.map((e) => e.message).join('\n')}`);
  }

  const {
    products: {
      items,
    },
  } = data;

  if (!items.length) {
    throw new Error('catalog product details not found');
  }

  return items[0];
};

export {
  // eslint-disable-next-line import/prefer-default-export
  getProductDetails,
};
