import { useQuery, useMutation } from 'react-query';

import { getContent, addItem } from './api/last-seen';

const useLastSeenQueryKey = ['last-seen'];

// eslint-disable-next-line max-len,arrow-body-style
const useLastSeen = (ignoreSku, enabled) => {
  // const data = useStaticQuery(graphql`query { siteBuildMetadata { buildTime } }`);

  return useQuery(useLastSeenQueryKey, () => getContent([ignoreSku]/* , data?.siteBuildMetadata?.buildTime */), {
    retry: false,
    refetchOnWindowFocus: true,
    enabled, /* && data?.siteBuildMetadata?.buildTime !== undefined, */
  });
};

const useAddToLastSeen = (queryClient) => useMutation(({ sku }) => addItem(sku), {
  onSettled: (status) => {
    // did add?
    if (status === true) {
      queryClient.invalidateQueries(useLastSeenQueryKey);
    }
  },
});

export { useLastSeen, useAddToLastSeen };
