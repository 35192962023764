import IdContentCache from '../../id-content-cache';
import { DefaultLifetime } from '../../cache';

class CompareCache extends IdContentCache {
  constructor() {
    super(
      'compare',
      1,
      DefaultLifetime,
    );
  }
}

export default CompareCache;
