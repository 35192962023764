import LastSeenCache from './last-seen-cache';
import { load } from './catalog';
import { queryTypes } from './query';

const MAX_ITEMS = 4;

const cache = new LastSeenCache(MAX_ITEMS);

const getContent = async (excludedSkus, cacheKey) => {
  // read list of skus from memory
  const knownSkus = await cache.getSkus();

  // ignore array of "excludedSkus"
  const lastSeenSkus = knownSkus
    .filter((sku) => !excludedSkus.includes(sku));

  if (lastSeenSkus.length === 0) {
    return [];
  }

  // query products based on resulting list of skus
  const { items } = await load(
    [{
      key: 'sku',
      options: lastSeenSkus.map((sku) => ({
        value: sku,
      })),
    }],
    null,
    [],
    MAX_ITEMS,
    1,
    null,
    queryTypes.items,
    cacheKey,
  );

  // sort items by order of skus
  return items.sort((a, b) => {
    const aIdx = lastSeenSkus.indexOf(a.sku);
    const bIdx = lastSeenSkus.indexOf(b.sku);
    return aIdx - bIdx;
  });
};

const addItem = (sku) => cache.addSku(sku);

// eslint-disable-next-line import/prefer-default-export
export { getContent, addItem };
