import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import Section from '../../../component-elements/content-section';

import useRemoteScript from '../../../lib/hooks/useRemoteScript';
import t from '../../../lib/translate';

const Loadbee = ({
  locale,
  gtin,
}) => {
  const [loadbeeActive, setLoadbeeActive] = useState(false);

  const { data: loadbeedata } = useRemoteScript(
    'https://cdn.loadbee.com/js/loadbee_integration.js',
    {
      enabled: loadbeeActive,
    },
  );

  useEffect(() => {
    if (!loadbeeActive || !window?.loadbeeIntegration) {
      return;
    }

    window.loadbeeIntegration.init();

    const timeout = setTimeout(() => {
      const loadbeeElement = document.querySelector('.loadbeeTabContent');
      const loadbeeSection = document.querySelector('.loadbee-section');

      if (loadbeeElement.childNodes.length === 0) {
        loadbeeSection.classList.add('d-none');
      } else {
        loadbeeSection.classList.remove('d-none');
      }
    }, 500);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(timeout);
      setLoadbeeActive(false);
    };
  }, [loadbeedata]);

  return (
    <VisibilitySensor
      resizeCheck={false}
      partialVisibility
      onChange={(isVisible) => {
        if (!isVisible || loadbeeActive) {
          return;
        }
        setLoadbeeActive(true);
      }}
    >
      <Section noGrid className="loadbee-section" background="Weiß">
        <header className="section-header">
          <h4>{t`product_loadbee_title`}</h4>
        </header>

        <div
          className="loadbeeTabContent"
          data-loadbee-apikey={process.env.GATSBY_LOADBEE_KEY}
          data-loadbee-gtin={gtin}
          data-loadbee-locale={locale}
        />
      </Section>
    </VisibilitySensor>
  );
};

Loadbee.propTypes = {
  locale: PropTypes.string.isRequired,
  gtin: PropTypes.string.isRequired,
};

export default Loadbee;
