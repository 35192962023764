/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import selectTheme from '../../../lib/react-select-theme';

import { useInput } from "../../../lib/hooks/useInput";

import * as styles from "./component.module.scss"
import t from "../../../lib/translate";

export default function CustomizeOption({
	option: {
		uid,
		title,
		type,
		required,
		dropDownValues,
	},
	setValue,
	initialValue,
	...other
}) {
  const { value, bind } = useInput(initialValue);
	const [portalTarget, setPortalTarget] = useState(undefined);
	useEffect(() => setPortalTarget(document.body), []);

  React.useEffect(() => {
    setValue(uid, value);
  }, [value]);

	switch (type) {
		case 'CustomizableFieldOption':
			return (
				<div className={`form-group ${styles.container}`}>
					<textarea
						name={`customize-option-${uid}`}
						className={`form-control ${styles.option}`}
						placeholder={title}
						defaultValue={initialValue}
						rows={1}
						{...bind}
						{...other}
					/>
				</div>
			);


		case 'CustomizableDropDownOption':
			return (
				<div className={`form-group ${styles.container} ${styles.selectContainer}`}>
					<label htmlFor={`customize-option-${uid}`} className={`form-label ${styles.formLabel}`}>{title} {required && <span className="required_asterisk"></span>}</label>
					<select
						id={`customize-option-${uid}`}
						name={`customize-option-${uid}`}
						className={`from-control ${styles.formControl} ${required && 'required'}`}
						placeholder={title}
						required={required}
						{...bind}
						{...other}
					>
						<option value="" selected={(initialValue === '' || initialValue === undefined) ? 'selected' : false}>{t`products_options_no_selection`}</option>
						{dropDownValues
							.sort((a, b) => a.sort_order - b.sort_order)
							.map(({title, price, option_type_id, price_type}) => (<option value={option_type_id} selected={(initialValue === option_type_id) ? 'selected' : false}>{title}</option>))}
					</select>
				</div>
			);
		default:
			return "test";
	}
}
