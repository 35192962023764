import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper/core';

import { propTypes as itemPropTypes } from '../item/propTypes';

import t from '../../../lib/translate';

import Section from '../../../component-elements/content-section';
import Product from '../item';

import 'swiper/swiper.scss';

SwiperCore.use([Pagination, Navigation, Autoplay]);

const ProductSlider = ({
  lang,
  headline,
  type,
  productDetailPath,
  items,
}) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <Section noGrid background="Weiß">
      <header className="section-header">
        <h4>{headline}</h4>
      </header>

      <div
        className={`slider product-slider has-items-${items.length}`}
      >
        <span className={`icon icon-left icon-gold swiper-button swiper-button-prev prev prev-${type}`}>
          <span className="sr-only">{t`pager_prev_label`}</span>
        </span>
        <span className={`icon icon-right icon-gold swiper-button swiper-button-next next next-${type}`}>
          <span className="sr-only">{t`pager_next_label`}</span>
        </span>
        <Swiper
          slidesPerView={
            items.length >= 3 ? 3 : items.length
          }
          spaceBetween={30}
          slidesPerGroup={1}
          loop
          watchOverflow
          loopedSlides={50}
          navigation={{
            prevEl: `.prev-${type}`,
            nextEl: `.next-${type}`,
          }}
          breakpoints={{
            1275: {
              slidesPerView: items.length >= 3 ? 3 : items.length,
            },
            767: {
              slidesPerView: items.length >= 2 ? 2 : items.length,
            },
            320: {
              slidesPerView: 1,
            },
          }}
          speed={1000}
          className={`has-items-${items.length}`}
        >
          {items.map((xSellProduct) => (
            <SwiperSlide key={xSellProduct.sku} className="swiper-slide">
              <Product
                lang={lang}
                urlPrefix={productDetailPath}
                withAddToWishlist={false}
                imageSize={520}
                {...xSellProduct}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Section>
  );
};

ProductSlider.propTypes = {
  lang: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  productDetailPath: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(itemPropTypes)).isRequired,
};

export default ProductSlider;
