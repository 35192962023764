import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

const Gallery = ({ images }) => {
  if (!images || images.length === 0) {
    return null;
  }

  return (
    <div className={`product-gallery product-gallery-size-${Math.floor((images.length - 1) / 8)} product-gallery-images-${(images.length)}`}>
      <Carousel
        index={0}
        canAutoPlay={false}
        hasLeftButton={false}
        hasRightButton={false}
        hasMediaButton={false}
        hasIndexBoard={false}
        shouldMaximizeOnClick
        shouldMinimizeOnClick
        shouldLazyLoad
        objectFit="contain"
        zIndexAtMax={2000}
        thumbnailWidth="20%"
        thumbnailHeight="20%"
        images={images}
        hasThumbnails={images.length > 1}
        minIcon={<span className="icon-gold icon-minimize" />}
        maxIcon={<span className="icon-gold icon-maximize" />}
      />
    </div>
  );
};

Gallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string.isRequired,
    srcset: PropTypes.string,
    sizes: PropTypes.string,
    thumbnail: PropTypes.string,
    alt: PropTypes.string,
  })).isRequired,
};

export default Gallery;
