import React, { useState } from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';

import EasycreditCalculator from '../easycredit-calculator';

import * as styles from './component.module.scss';

const ExternalActions = ({
  sku,
  finalPrice,
}) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <VisibilitySensor
      active={!isActive}
      resizeCheck={false}
      partialVisibility
      onChange={(isVisible) => {
        if (!isVisible || isActive === true) {
          return;
        }
        setIsActive(true);
      }}
    >
      <div className={`product-external-actions ${styles.externalActions}`}>
        <EasycreditCalculator
          sku={sku}
          price={finalPrice}
        />
      </div>
    </VisibilitySensor>
  );
};

ExternalActions.propTypes = {
  sku: PropTypes.string.isRequired,
  finalPrice: PropTypes.number.isRequired,
};

export default ExternalActions;
