import { useEffect, useMemo, useState } from 'react';

import { useCart } from '../../../lib/service/cart/useCart';

import { getProductPrice } from '../price';
import { useProductDetails } from '../../../lib/service/catalog/product-details';

const useProductView = ({
    product,
    cartItemUid,
}) => {
    // handle edit mode

    const [customOptionValues, setCustomOptionValues] = useState({}); // { uid-of-option: 'values as space-separated string' }
    const [qty, setQty] = useState(1);

    const isEditMode = cartItemUid?.length > 0;

    const {
        isLoading: isCartLoading,
        isError: isCartError,
        data: cartItems,
    } = useCart({
        enabled: isEditMode,
    }, `edit-${cartItemUid ?? 'none'}`);

    // find the cart item for the given cart item uid
    const cartItemToEdit = useMemo(() => {
        if (!isEditMode) {
            return undefined;
        }
        return (cartItems ?? []).find(({ uid }) => uid === cartItemUid);
    }, [isEditMode, cartItems]);

    // set cart item quantity and selected option values
    useEffect(() => {
        if (!isEditMode || !cartItemToEdit) {
            return;
        }

        setQty(cartItemToEdit.quantity);

        const selectedItemOptions = cartItemToEdit.customizable_options.reduce((carry, { uid, values }) => {
            const reduction = {
                ...carry,
            };
            reduction[uid] = values.map(({ value }) => value).join(' ');
            return reduction;
        }, {});

        setCustomOptionValues(selectedItemOptions);
    }, [isEditMode, cartItemToEdit]);

    const setCustomOptionValue = (uid, value) => {
        const hasItem = Object.keys(customOptionValues).includes(uid);
        if (!hasItem && !value) {
            // don't add an item with empty value
            return;
        }

        let newState;

        if (hasItem && !value) {
            // remove item
            newState = Object.keys(customOptionValues)
                .filter((itemUid) => itemUid !== uid)
                .reduce((carry, itemUid) => ({
                    ...carry,
                    [itemUid]: customOptionValues[itemUid],
                }), {});
        } else {
            // add or modify item
            newState = {
                ...customOptionValues,
                [uid]: value,
            };
        }

        setCustomOptionValues(newState);
    };

    // load product details
    const {
        isLoading: isDetailsLoading,
        isError: isDetailError,
        data: loadedProductDetails = {},
    } = useProductDetails(product.sku);

    const updatedProduct = {
        ...product,
        ...loadedProductDetails,
    };

    // product related
    const { finalPrice } = getProductPrice(updatedProduct);

    const galleryImages = (product.media_gallery || [])
        .sort((a, b) => a.position - b.position)
        .map((image, index) => ({
            src: image.srcMobile,
            thumbnail: image.thumbnail,
            srcset: `${image.srcMobile} 420w, ${image.srcTablet} 790w, ${image.src} 1280w`,
            sizes: '(max-width: 500) 420px, (max-width: 1000px) 790px, 1280px',
            ...(index === 0 ? { alt: `${product.image.label.trim()} | ${product.jr_manufacturer_number}` } : {})
        }));

    //
    return {
        product: updatedProduct,
        isLoading: isCartLoading || isDetailsLoading,
        isError: isCartError || isDetailError,
        isEditMode: isEditMode && cartItemToEdit !== undefined,
        cartQty: qty,
        customOptionValues,
        setCustomOptionValue,
        // resetCustomOptionValues: () => setCustomOptionValues({}),
        fullProductName: `${product.cmsBrand ? `${product.cmsBrand.name} ` : ''}${product.name.trim()}`,
        gtin: product.jr_ean || product.jr_manufacturer_number,
        finalPrice,
        galleryImages: galleryImages,
        isProductReleased: product.jr_release_date == null ? true : Date.parse(product.jr_release_date) <= Date.now(),
    };
};

export default useProductView;
