import AccountCache from '../../account/api/cache';

import CartCache from './cache';
import { createCart } from './createCart';
import { request } from './request';
import { CartBackendResponseError } from './errors';

const accountCache = new AccountCache();
const cartCache = new CartCache();

const query = `mutation($cartId: String!, $sku: String!, $quantity: Float! $options: [EnteredOptionInput!]!) {
  addProductsToCart(
    cartId: $cartId
    cartItems: [
      {
        sku: $sku
        quantity: $quantity
        entered_options: $options
      }
    ]
  ) {
    user_errors {
      code
      message
    }
  }
}`;

const addToCart = async (sku, quantity, options = {}) => {
  let cartId = await cartCache.getId();
  if (!cartId) {
    cartId = await createCart();
  }

  const accountId = await accountCache.getId();

  let response;

  try {
    response = await request(
      query,
      {
        cartId,
        quantity,
        sku,
        options: Object.keys(options)
          .map((option) => ({
            uid: option,
            value: options[option],
          }))
          .filter(({ uid, value }) => uid?.length > 0 && value?.length > 0),
      },
      accountId,
    );
  } catch (e) {
    if (e instanceof CartBackendResponseError) {
      await cartCache.clear();
      if (accountId) {
        await accountCache.clear();
      }
    }
    throw e;
  }

  await cartCache.removeContent();

  const {
    addProductsToCart: {
      user_errors: userErrors,
    },
  } = response;

  if (userErrors && userErrors.length > 0) {
    throw new Error(userErrors[0].message);
  }

  return true;
};

// eslint-disable-next-line import/prefer-default-export
export { addToCart };
