import { useQuery } from 'react-query';

import { getProductDetails } from './api/product-details';

const useProductDetailsQueryKey = ['product-details'];

const useProductDetails = (sku) => useQuery([...useProductDetailsQueryKey, sku], () => getProductDetails(sku), {
  retry: false,
  refetchOnWindowFocus: false,
});

export {
  // eslint-disable-next-line import/prefer-default-export
  useProductDetails,
};
