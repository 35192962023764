/* eslint-disable quote-props */
import React, { useEffect, useMemo, useRef } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { StructuredText } from 'react-datocms';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import t from '../../../lib/translate';
import useIsTabletOrMobileMediaQuery from '../../../lib/hooks/useIsTabletOrMobileMediaQuery';

import useProductView from './useProductView';

import Layout from '../../../layouts/default';

import Breadcrumb from '../../../component-elements/breadcrumb';
import Section from '../../../component-elements/content-section';

import PaymentMethodLogos from '../../payment-method-logos';
import { StructuredData } from '../../structured-data';
import LoadingButton from '../../LoadingButton';

import Head from '../head';
import ProductFlags from '../flags';
import Gallery from '../gallery';
import AddToWishlist from '../add-to-wishlist';
import AddToCompare from '../add-to-compare';
import CustomizeOption from '../customize-option';
import AddToCart from '../add-to-cart';
import ExternalActions from '../external-actions';
import Accordion from '../../accordion';
import Loadbee from '../loadbee';
import LastSeen from '../last-seen';
import CmsContent from '../cms-content';
import ProductSlider from '../product-slider';
import Price from '../price';
import { SinglePlaceholder } from '../item/components/placeholder';

import { refLinks, refSpacer, inlineInquiryBtn } from './component.module.scss';

const ProductView = ({
    product: staticProductData,
    lang,
    productDetailPath,
    inquiryUrlPath,
    detectiveUrlPath,
    breadcrumb,
    cmsContent,
    replaceCartUid,
    url
}) => {
    const isTabletOrMobileLayout = useIsTabletOrMobileMediaQuery();

    const {
        isLoading,
        isError,
        isEditMode,
        cartQty,
        customOptionValues,
        setCustomOptionValue,
        fullProductName,
        gtin,
        finalPrice,
        galleryImages,
        isProductReleased,
        product
    } = useProductView({
        product: staticProductData,
        cartItemUid: replaceCartUid
    });

    const inquiryButtonRef = useRef(null);

    const technicalDetails = useMemo(() => {
        if (product?.detailPageAttributes?.length < 1) {
            return null;
        }

        return product.detailPageAttributes.sort(({ position: posA }, { position: posB }) => posA - posB);
    }, [product?.detailPageAttributes]);

    const productEcommerceEventData = {
        value: product.price_without_tax, // Nettowert des Produkts bzw. bei Quantity > 1 Gesamtwert der Produkte
        currency: 'EUR',
        items: [
            {
                item_id: product.sku,
                item_name: fullProductName,
                index: 0, // Da Produkte einzeln in den Warenkorb gelegt werden, einfach 0 lassen.
                item_brand: product.cmsBrand?.name,
                price: product.price_without_tax, // Netto-Preis des Produkts bei Quantität 1
                quantity: 1
            }]
    };

    if (product.categories) {
        productEcommerceEventData.items[0].item_category = product.categories[1]?.name; // optional (skipping Index 0 as it is always "Juwelier Roller"
        productEcommerceEventData.items[0].item_category2 = product.categories[2]?.name; // optional
        productEcommerceEventData.items[0].item_category3 = product.categories[3]?.name; // optional
        productEcommerceEventData.items[0].item_category4 = product.categories[4]?.name; // optional
        productEcommerceEventData.items[0].item_category5 = product.categories[5]?.name; // optional
    }

    const productInquiryEvent = {
        event: 'click_produkt_anfragen',
        ecommerce: productEcommerceEventData
    };

    const productAddToCartEvent = {
        event: 'add_to_cart',
        ecommerce: productEcommerceEventData
    };

    const breadcrumbStructuredDataItems = [
    ];

    // build breadcrumb based on brand and article line

    const brandBreadcrumb = [];

    if (product.cmsBrand) {
        brandBreadcrumb.push({ id: product.cmsBrand.id, name: product.cmsBrand.name, url: product.cmsBrand.fullUrlPath });
    }

    if (product.cmsArticleLine) {
        brandBreadcrumb.push({ id: product.cmsArticleLine.name, name: product.cmsArticleLine.name, url: product.cmsArticleLine.fullUrlPath });
    }

    if (brandBreadcrumb.length > 0) {
        brandBreadcrumb.push({ id: product.sku, name: fullProductName });
        breadcrumbStructuredDataItems.push(brandBreadcrumb);
    }

    // build breadcrumb based on article group and type

    const articleGroupBreadcrumb = [];

    if (product.dwArticleGroupCategory) {
        articleGroupBreadcrumb.push({ id: product.dwArticleGroupCategory.id, name: product.dwArticleGroupCategory.name, url: product.dwArticleGroupCategory.url_path });
    }

    if (product.dwArticleTypeCategory) {
        articleGroupBreadcrumb.push({ id: product.dwArticleTypeCategory.id, name: product.dwArticleTypeCategory.name, url: product.dwArticleTypeCategory.url_path });
    }

    if (articleGroupBreadcrumb.length > 0) {
        articleGroupBreadcrumb.push({ id: product.sku, name: fullProductName });
        breadcrumbStructuredDataItems.push(articleGroupBreadcrumb);
    }


    // if still empty, add visible sitemap to have any

    if (breadcrumbStructuredDataItems.length === 0) {
        breadcrumbStructuredDataItems.push(
            [
                ...breadcrumb,
                { id: product.sku, name: fullProductName }
            ]
        );
    }

    useEffect(() => {
        if (!inquiryButtonRef) return

        const inlineButtons = document.querySelectorAll(`.${inlineInquiryBtn}`) ?? []
        const clickInquiryButton = () => inquiryButtonRef.current?.click()

        inlineButtons.forEach(button => {
            button.addEventListener('click', clickInquiryButton)
        })

        return () => {
            inlineButtons.forEach(button => {
                button.removeEventListener('click', clickInquiryButton)
            })
        }
    }, [product.description])

    return (
        <Layout textcolorHeader="black" textcolorHeaderMobile="black">
            <Head
                product={product}
                metaTitle={product.meta_title ?? t('product_seo_title_fallback', fullProductName)}
                metaDescription={product.meta_description ?? t('product_seo_description_fallback',
                    fullProductName)}
                metaCanonical={url}
                imageUrl={galleryImages.length > 0 ? galleryImages[0].src : undefined}
            />

            <Breadcrumb
                items={[
                    ...breadcrumb,
                    { id: product.sku, name: fullProductName }
                ]}
                className="product-breadcrumb"
                structuredDataItems={breadcrumbStructuredDataItems}
						showAtlas={true}
            />

            {isError && (
                <Section background="Weiß" className="content-section-product">
                    {t`product_details_err`}
                </Section>
            )}

            <Section background="Weiß" className="content-section-product">
                <div className="col-lg-8 offset-lg-8">
                    <div className="product-details">
                        <header className="product-header">
                            {product.cmsBrand && (
                                <Link to={product.cmsBrand.fullUrlPath} className="product-brand-link">
                                    {product.cmsBrand.logoGold?.gatsbyImageData ? (
                                        <GatsbyImage
                                            image={getImage(product.cmsBrand.logoGold.gatsbyImageData)}
                                            alt={product.cmsBrand.logoGold.alt}
                                            title={product.cmsBrand.logoGold.title}
                                        />
                                    ) : (
                                        <>{product.cmsBrand.name}</>
                                    )}
                                </Link>
                            )}
                            {!product.cmsBrand && <span />}

                            <div className="product-add-buttons">
                                <AddToWishlist sku={product.sku} />
                                <AddToCompare id={product.magentoId} />
                            </div>

                            <div className={`product-image-gallery-container ${galleryImages.length > 1 ? 'col-md-12 offset-md-2' : 'col-md-10 offset-md-3'} col-lg-14 offset-lg-0`}>
                                <ProductFlags {...product.dwFlags} />
                                <Gallery images={galleryImages} />
                            </div>

                            <h1>{fullProductName}</h1>
                        </header>

                        <div className="product-price-wrapper">
                            <div className="product-price-container">
                                {isLoading && (
                                    <SinglePlaceholder center={false} className="product-price" width="115px" />
                                )}
                                {!isLoading && !isError && (
                                    <Price
                                        lang={lang}
                                        classNamePrefix="product-"
                                        product={product}
                                        showTax
                                    />
                                )}

                                {/* Produkt noch nicht veröffentlicht */}
                                {!isProductReleased && product.jr_manufacturer_status && (
                                    <div className="product-delivery product-delivery-duration-soon">
                                        {/* todo: "releaseDate" tatsächlich? */}
                                        {t('product_delivery_release_date', product.releaseDate)}
                                    </div>
                                )}

                                {/* Produkt veröffentlicht */}
                                {isLoading && (
                                    <div className="product-delivery product-delivery-duration-unknown">
                                        <SinglePlaceholder center={false} className="product-tax"
                                            width="215px" />
                                        <SinglePlaceholder center={false} className="product-delivery"
                                            width="175px" />
                                    </div>
                                )}

                                {!isLoading && !isError && isProductReleased && product.jr_manufacturer_status && (product.dwInStock || product.jr_reorderable) && (
                                    <>
                                        {/* Produkt verfügbar */}
                                        {((product.jr_delivery_time !== null && product.jr_delivery_time !== 'Anfrage') || product.dwInStock) ? (
                                            <div
                                                className={`product-delivery product-delivery-duration-${product.dwInStock
                                                    || product.jr_delivery_time === '1-3t'
                                                    ? 'now'
                                                    : 'soon'
                                                    }`}
                                            >
                                                <span className="mr-2">{t`product_delivery_time`}</span>
                                                {t(
                                                    `product_delivery_time_${product.dwInStock
                                                        ? '1-3t'
                                                        : product.jr_delivery_time
                                                    }`
                                                )}
                                            </div>
                                        ) : (
                                            <div
                                                className="product-delivery product-delivery-duration-unknown"
                                            >
                                                <span className="mr-2">{t`product_delivery_time_unknown`}</span>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>

                            {!isLoading && !isError && (isProductReleased || product.jr_preorderable) && product.jr_manufacturer_status && (product.dwInStock || product.jr_reorderable) && (
                                <ExternalActions
                                    name={fullProductName}
                                    finalPrice={finalPrice}
                                    sku={product.sku}
                                />
                            )}
                        </div>

                        {/* Herstellerfreigabe erteilt */}
                        {(isProductReleased || product.jr_preorderable) && product.jr_manufacturer_status && (
                            (product.dwInStock || product.jr_reorderable) ? (
                                <>
                                    <div className="product-actions">
                                        {product?.customizeOptions?.length > 0 && product.customizeOptions.sort(
                                            (a, b) => a.sort_order - b.sort_order).map((option) => (
                                                <CustomizeOption
                                                    key={option.uid}
                                                    option={option}
                                                    initialValue={isEditMode ? customOptionValues[option.uid] : undefined}
                                                    setValue={setCustomOptionValue}
                                                />
                                            ))}

                                        {isLoading && (
                                            <LoadingButton
                                                type="button"
                                                className="product-button-cart"
                                                isLoading
                                            />
                                        )}

                                        {!isLoading && !isError && (
                                            <AddToCart
                                                sku={product.sku}
                                                qty={cartQty}
                                                customOptions={customOptionValues}
                                                onSuccess={() => {
                                                    window?.dataLayer?.push(productAddToCartEvent);
                                                }}
                                                replaceUid={isEditMode ? replaceCartUid : undefined}
                                                preorder={!isProductReleased && product.jr_preorderable}
                                            />
                                        )}

                                        {inquiryUrlPath && (
                                            <Link
                                                ref={inquiryButtonRef}
                                                className="btn btn-secondary product-button-inquiry"
                                                to={inquiryUrlPath}
                                                onClick={() => window?.dataLayer?.push(
                                                    productInquiryEvent)}
                                                state={{
                                                    name: fullProductName,
                                                    product: product
                                                }}
                                            >
                                                {t`product_action_request_label`}
                                            </Link>
                                        )}
                                    </div>
                                </>
                            )
                                : (product.jr_investigate && detectiveUrlPath) ? (
                                    <>
                                        {/* Produkt nicht verfügbar */}
                                        {/* Detektivfunktion an -> Detektiv */}
                                        <p>{t`product_not_available_detective`}</p>
                                        <div className="product-actions">
                                            <Link
                                                ref={inquiryButtonRef}
                                                className="btn btn-secondary product-button-inquiry"
                                                to={detectiveUrlPath}
                                                state={{
                                                    name: fullProductName,
                                                    product: product
                                                }}
                                            >
                                                {t`product_action_detective_label`}
                                            </Link>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {/* Produkt nicht verfügbar */}
                                        {/* Detektivfunktion aus -> Anfrage */}
                                        <p>{t`product_not_available`}</p>
                                        <div className="product-actions">
                                            {inquiryUrlPath && (
                                                <Link
                                                    ref={inquiryButtonRef}
                                                    className="btn btn-secondary product-button-inquiry"
                                                    to={inquiryUrlPath}
                                                    onClick={() => window?.dataLayer?.push(
                                                        productInquiryEvent)}
                                                    state={{
                                                        name: fullProductName,
                                                        product: product
                                                    }}
                                                >
                                                    {t`product_action_request_label`}
                                                </Link>
                                            )}
                                        </div>
                                    </>
                                )
                        )}

                        {/* Herstellerfreigabe aus oder noch nicht veröffentlicht ohne Vorbestellbarkeit -> Anfrage */}
                        {(!product.jr_manufacturer_status || (!isProductReleased && !product.jr_preorderable)) && (
                            <>
                                <p>{t`product_not_available_manufacturer_status_off`}</p>

                                <div className="product-actions">
                                    {inquiryUrlPath && (
                                        <Link
                                            ref={inquiryButtonRef}
                                            className="btn btn-secondary product-button-inquiry"
                                            to={inquiryUrlPath}
                                            onClick={() => window?.dataLayer?.push(productInquiryEvent)}
                                            state={{
                                                name: fullProductName,
                                                product: product
                                            }}
                                        >
                                            {t`product_action_request_label`}
                                        </Link>
                                    )}
                                </div>
                            </>
                        )}

                        {(product.cmsArticleLine || product.cmsBrand) && (
                            <div className={refLinks}>
                                {product.cmsArticleLine && (
                                    <Link to={product.cmsArticleLine.fullUrlPath}>
                                        {t('product_articleline_link_label', product.cmsArticleLine.name)}
                                    </Link>
                                )}

                                {product.cmsArticleLine && product.cmsBrand && (
                                    <span className={refSpacer} />
                                )}

                                {product.cmsBrand && (product.cmsBrand.occasionCollectionsCount > 0 || product.cmsBrand.productCollectionsCount > 0) && (
                                    <Link to={product.cmsBrand.collectionsUrlPath}>
                                        {t('product_collections_link_label', product.cmsBrand.name)}
                                    </Link>
                                )}

                                {product.cmsBrand && (product.cmsBrand.occasionCollectionsCount === 0 && product.cmsBrand.productCollectionsCount === 0) && (
                                    <Link to={product.cmsBrand.productsUrlPath}>
                                        {t('product_brand_products_link_label', product.cmsBrand.name)}
                                    </Link>
                                )}
                            </div>
                        )}

                        <div className="product-numbers">
                            <dl>
                                <dt>{t`product_sku_label`}</dt>
                                <dd>{product.sku}</dd>
                                {product.jr_manufacturer_number && (
                                    <>
                                        <dt>{t`product_manufacturer_number_label`}</dt>
                                        <dd>{product.jr_manufacturer_number}</dd>
                                    </>
                                )}
                                {product.jr_ean && (
                                    <>
                                        <dt>{t`product_ean_label`}</dt>
                                        <dd>{product.jr_ean}</dd>
                                    </>
                                )}
                            </dl>
                        </div>

                    </div>
                </div>
                <div className="col-xl-8 offset-xl-8 ">
                    <div className="product-accordion accordion">
                        {/* --------------------------- Product Description -------------------------- */}
                        <Accordion
                            title={t`product_description_label`}
                            defaultActive={!isTabletOrMobileLayout}
                        >
                            {/* eslint-disable react/no-danger */}
                            <div
                                itemProp="description"
                                dangerouslySetInnerHTML={{
                                    __html: product.description.html.replace(/\[\[([^\]]+)\]\]/g, (_, $1) => `<button class="${inlineInquiryBtn}">${$1}</button>`)
                                }}
                            />
                        </Accordion>
                        {/* -------------------------------------------------------------------------- */}

                        {/* ---------------------------- Technical Details --------------------------- */}
                        {technicalDetails && (
                            <Accordion
                                title={t`product_attributes_label`}
                            >
                                <dl>
                                    {technicalDetails.map((attrib) => (
                                        <React.Fragment key={attrib.code}>
                                            <dt>{attrib.label}</dt>
                                            {/* eslint-disable-next-line react/no-danger */}
                                            <dd
                                                dangerouslySetInnerHTML={{
                                                    __html: attrib.textValues.join('<br />')
                                                }}
                                            />
                                        </React.Fragment>
                                    ))}
                                </dl>
                            </Accordion>
                        )}
                        {/* -------------------------------------------------------------------------- */}

                        {product.cmsBrand && product.cmsBrand.description?.value && (
                            <Accordion
                                title={t(
                                    'product_brand_description_label',
                                    product.cmsBrand.name
                                )}
                            >
                                <GatsbyImage
                                    image={getImage(
                                        product.cmsBrand.logoBlack.gatsbyImageData
                                    )}
                                    alt={product.cmsBrand.logoBlack.alt}
                                    title={product.cmsBrand.logoBlack.title}
                                    as="p"
                                />
                                <StructuredText data={product.cmsBrand.description} />
                            </Accordion>
                        )}
                    </div>
                </div>
            </Section>

            {gtin && (<Loadbee gtin={gtin} locale="de_DE" />)}

            <ProductSlider
                lang={lang}
                type="related"
                headline={t`product_related_title`}
                productDetailPath={productDetailPath}
                items={product.related}
            />

            <CmsContent elements={cmsContent} />

            <ProductSlider
                lang={lang}
                type="xsell"
                headline={t`product_xsell_title`}
                productDetailPath={productDetailPath}
                items={product.crossSelling}
            />

            <LastSeen sku={product.sku} urlPrefix={productDetailPath} lang={lang} />

            <PaymentMethodLogos lang={lang} />

            <StructuredData
                type="Product"
                {...{
                    name: product.name.trim(),
                    image: product.image?.url,
                    description: product.description?.text,
                    sku: product.sku,
                    brand: product.cmsBrand ? {
                        '@type': 'Brand',
                        name: product.cmsBrand.name
                    } : undefined,
                    offers: {
                        '@type': 'Offer',
                        priceCurrency: 'EUR',
                        price: finalPrice,
                        url: `${process.env.GATSBY_WEB_URL}/${productDetailPath}/${product.urlKey}/`
                    }
                }}
            />
        </Layout>
    );
};

ProductView.defaultProps = {
    productDetailPath: undefined,
    inquiryUrlPath: undefined,
    detectiveUrlPath: undefined,
    replaceCartUid: undefined,
    url: undefined,
    breadcrumb: [],
    cmsContent: []
};

ProductView.propTypes = {
    product: PropTypes.objectOf(PropTypes.any).isRequired,
    lang: PropTypes.string.isRequired,
    productDetailPath: PropTypes.string,
    inquiryUrlPath: PropTypes.string,
    detectiveUrlPath: PropTypes.string,
    replaceCartUid: PropTypes.string,
    url: PropTypes.string,
    breadcrumb: PropTypes.arrayOf(PropTypes.any),
    cmsContent: PropTypes.arrayOf(PropTypes.any)
};

export default ProductView;
